<template>
  <div>
    <NTabs
      type="line"
      @before-leave="handleTabBeforeLeave"
      @update:value="handleTabChange"
    >
      <n-tab-pane
        v-for="item in resourceTypes"
        :key="item.name"
        :name="item.name"
        :tab="item.tab"
      >
        <div class="pane-num pane-num-2" v-if="JselectedIdsArr.length > 0">
          {{ JselectedIdsArr.length }}
        </div>
        <div class="pane-num pane-num-1" v-if="KselectedIdsArr.length > 0">
          {{ KselectedIdsArr.length }}
        </div>
        <div class="pane-num pane-num-3" v-if="SselectedIdsArr.length > 0">
          {{ SselectedIdsArr.length }}
        </div>

        <NForm inline ref="searchFormRef" label-placement="left">
          <NFormItem label="搜索：">
            <NInput
              maxlength="20"
              show-count
              clearable
              v-model:value="searchParams['School[title]']"
              @keyup.enter="handleSearch"
            />
          </NFormItem>
          <!-- 类型 习题 试卷 -->
          <NFormItem label="类型：" v-if="isActive == 1">
            <div style="width: 200px">
              <NSelect
                :options="typeOptions1"
                v-model:value="searchParams['School[state]']"
              />
            </div>
          </NFormItem>
          <!-- 类型 停用 启用 -->
          <NFormItem label="类型：" v-if="isActive == 3 || isActive == 4">
            <div style="width: 200px">
              <NSelect
                :options="typeOptions2"
                v-model:value="searchParams['School[state]']"
              />
            </div>
          </NFormItem>
          <NFormItem>
            <NSpace>
              <NButton type="primary" @click="handleSearch">搜索</NButton>
              <NButton @click="handleClear">清除</NButton>
            </NSpace>
          </NFormItem>
        </NForm>
        <!-- 课程系统 -->
        <NDataTable
          v-if="isActive === 1"
          size="small"
          :columns="Kcolumns"
          :row-key="(row) => row.id"
          :data="KtableData"
          remote
          :pagination="pagination"
          @update:page="handlePageChange"
          @update:page-size="handlePageSizeChange"
          :default-checked-row-keys="KselectedIdsArr"
          @update:checked-row-keys="KhandleSelect"
        ></NDataTable>
        <!-- 教学系统 -->
        <NDataTable
          v-if="isActive === 2"
          size="small"
          :columns="Jcolumns"
          :row-key="(row) => row.id"
          :data="JtableData"
          remote
          :pagination="pagination"
          @update:page="handlePageChange"
          @update:page-size="handlePageSizeChange"
          :default-checked-row-keys="JselectedIdsArr"
          @update:checked-row-keys="JhandleSelect"
        ></NDataTable>
        <!-- 实训系统 -->
        <NDataTable
          v-if="isActive === 3"
          size="small"
          :columns="Scolumns"
          :row-key="(row) => row.id"
          :data="StableData"
          remote
          :pagination="pagination"
          @update:page="handlePageChange"
          @update:page-size="handlePageSizeChange"
          :default-checked-row-keys="SselectedIdsArr"
          @update:checked-row-keys="ShandleSelect"
        ></NDataTable>
      </n-tab-pane>
    </NTabs>
    <NSpace
      justify="end"
      style="
        margin: 12px -28px 0;
        padding: 15px 28px 0;
        border-top: 1px solid #ddd;
      "
    >
      <span style="line-height: 34px; color: #999"
        >已选：<span class="theme-color">{{
          ZselectedIdsArr.length +
          JselectedIdsArr.length +
          KselectedIdsArr.length +
          SselectedIdsArr.length
        }}</span></span
      >
      <NButton @click="emit('modal-close')">取消</NButton>
      <NButton
        type="primary"
        :disabled="
          ZselectedIdsArr.length +
            JselectedIdsArr.length +
            KselectedIdsArr.length +
            SselectedIdsArr.length ===
          0
        "
        @click="nextStep"
        >下一步</NButton
      >
    </NSpace>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, defineExpose, computed, h } from "vue";

import { NSpace, NButton, NTime } from "naive-ui";

import PageLoading from "@/components/PageLoading/index.vue";

import { PostDistribute } from "@/api/school.js";

import { resStatusEnum } from "@/enumerators/http.js";

const { SUCCESS } = resStatusEnum;

const loading = ref(false);
const isActive = ref(1);
const emit = defineEmits([
  "step-next",
  "id-label-update",
  "id-jlabel-update",
  "id-klabel-update",
  "id-slabel-update",
  "modal-close",
]);
const props = defineProps({
  labelType: {
    default: "",
  },
});

const resourceTypes = [
  { tab: '课程系统', name: 1 },
  { tab: '教学系统', name: 2 },
  { tab: '实训系统', name: 3 },
];

const ISactive = ref(1);
const typeOptions1 = ref([
  {
    label: "教案",
    value: 1,
  },
  {
    label: "习题",
    value: 2,
  },
  { label: "试卷", value: 3 },
  { label: "全部", value: '' },
]);

const typeOptions2 = ref([
  {
    label: "已停用",
    value: 0,
  },
  {
    label: "使用中",
    value: 1,
  },
  { label: "全部", value: 2 },
]);

function handleTabBeforeLeave() {
  return !loading.value;
}

// 数据集 按拼音的第一个字符
const tableData = ref([]);

const ZtableData = ref([]); // 所有页资源数据
const JtableData = ref([]); // 所有页教材数据
const KtableData = ref([]); // 所有页课程数据
const StableData = ref([]); // 所有页实训数据

const temporaryZtableData = ref([]); // 临时资源数据
const temporaryJtableData = ref([]); // 临时教材数据
const temporaryKtableData = ref([]); // 临时课程数据
const temporaryStableData = ref([]); // 临时实训数据

const currentType = ref(1);
function handleTabChange(name) {
  // searchParams["School[page]"] = 1
  // console.log('searchParams["School[page]"]',searchParams["School[page]"]);
  
  let index = Number(name);
  isActive.value = index; // 判断赋值
  searchParams["School[data_type]"] = index; // 类型赋值
  currentType.value = index;
  const resourceType = String(currentType.value);
  // 教材数据
  if (index == 2) {
    tableData.value = temporaryJtableData.value;
  }
  // 课程数据
  else if (index == 1) {
    tableData.value = temporaryKtableData.value;
  }
  // 实训数据
  else if (index == 3) {
    tableData.value = temporaryStableData.value;
  }
  // 初始化类型
  searchParams["School[state]"] = "";
  // 初始化数据
  handleSearch()
}

const tableDataCache = (() => {
  const cacheObj = {};
  return reactive(cacheObj);
})();

const nextStep = () => {
  emit("step-next");
};
// 组件间传值
const resetStep = () => {
  ZhandleSelect([]);
  JhandleSelect([]);
  KhandleSelect([]);
  ShandleSelect([]);
};
defineExpose({
  handleClear,
  resetStep,
});

const getDefaultSearchParams = () => ({
  "School[id]": localStorage.getItem("schoolId"), //学校ID
  "School[type]": 1, // 类型(1分发列表 2执行分发)
  "School[data_type]": 1, // 类型(1资源，2教材，3课程，4实训书)
  "School[state]": "", //资源搜索类型  (全部显示参数不传 1资源，2习题，3试卷)    (全部显示参数不传 0暂停1开通)
  "School[label]": [], // 选中的标签ID
  "School[material]": [], // 选中的教材ID
  "School[course]": [], // 选中的课程ID
  "School[task]": [], // 选中的实训ID
  "School[title]": "",
});

const searchFormRef = ref(null);
const searchParams = reactive(getDefaultSearchParams());

const handleSearch = () => {
  handlePageChange(1);
};
const handleClear = () => {
  Object.assign(searchParams, getDefaultSearchParams());
  handleSearch();
};
// tableData.value = ZtableData.value

// 资源表格内容
const Zcolumns = [
  { type: "selection" ,key: "id"},
  { title: "ID", key: "id" },
  { title: "名称", key: "base_name" },
  { title: "创建人", key: "admin_username" },
  { title: "更新日期", render: ({ update_time }) => h(NTime, { time: update_time * 1000 }) },
  /*
  {
    title: "类型",
    render: (row) =>
      h("span", null, { default: () => getResourceName(row.type) }),
  },
  */
];

// 教材表格内容
const Jcolumns = [
  { type: "selection" },
  { title: "ID", key: "id" },
  { title: "名称", key: "base_name" },
  { title: "创建人", key: "admin_username" },
  { title: "更新日期", render: ({ update_time }) => h(NTime, { time: update_time * 1000 }) },
];

// 课程表格内容
const Kcolumns = [
  { type: "selection" },
  { title: "ID", key: "id" },
  { title: "名称", key: "base_name" },
  { title: "创建人", key: "admin_username" },
  { title: "更新日期", render: ({ update_time }) => h(NTime, { time: update_time * 1000 }) },
  /*
  {
    title: "状态",
    render: (row) =>
      h("span", null, { default: () => getResourceName1(row.stop) }),
  },
  */
];
// 实训任务书内容
const Scolumns = [
  { type: "selection" },
  { title: "ID", key: "id" },
  { title: "名称", key: "title" },
  { title: "创建人", key: "admin_username" },
  { title: "更新日期", render: ({ update_time }) => h(NTime, { time: update_time * 1000 }) },
  /*
  {
    title: "状态",
    render: (row) =>
      h("span", null, { default: () => getResourceName1(row.stop) }),
  },
  */
];

const pagination = reactive({
  page: 1,
  itemCount: 0,
  pageSize: 10,
  pageSizes: [10, 20, 30],
  showSizePicker: true,
});
const updateItemCount = (count) => {
  pagination.itemCount = Number(count) || 0;
};
const handlePageChange = (page) => {
  pagination.page = page;
  searchParams["School[page]"] = page;
  updateList();
};
const handlePageSizeChange = (pageSize) => {
  pagination.pageSize = pageSize;
  searchParams["School[page_size]"] = pageSize;
  handlePageChange(1);
};
// 数据初始化
const updateList = () => {
  loading.value = true;
  let id = localStorage.getItem("schoolId");
  PostDistribute(searchParams)
    .then((res) => {
      loading.value = false;
      if (res.code === SUCCESS) {
        let index = searchParams["School[data_type]"];

        // 教材数据
         if (index == 2) {
          // 教材数据
          JtableData.value = res.data.list
          if(searchParams["School[page]"]!=1){
            let data = temporaryJtableData.value.concat(res.data.list)
            temporaryJtableData.value = data
            JupdateIdLabelMap(temporaryJtableData.value.concat(res.data.list));
          }else{
            let data = temporaryJtableData.value.concat(res.data.list)
            temporaryJtableData.value = data
            JupdateIdLabelMap(temporaryJtableData.value);
          }
        }
        // 课程数据
        else if (index == 1) {
          // 课程数据
          KtableData.value = res.data.list
          if(searchParams["School[page]"]!=1){
            let data = temporaryKtableData.value.concat(res.data.list)
            temporaryKtableData.value = data
            KupdateIdLabelMap(temporaryKtableData.value.concat(res.data.list));
          }else{
            let data = temporaryKtableData.value.concat(res.data.list)
            temporaryKtableData.value = data
            KupdateIdLabelMap(temporaryKtableData.value);
          }
        }
        // 实训数据
        else if (index == 3) {
          // 实训数据
          StableData.value = res.data.list
          if(searchParams["School[page]"]!=1){
            let data = temporaryStableData.value.concat(res.data.list)
            temporaryStableData.value = data
            SupdateIdLabelMap(temporaryStableData.value.concat(res.data.list));
          }else{
            let data = temporaryStableData.value.concat(res.data.list)
            temporaryStableData.value = data
            SupdateIdLabelMap(temporaryStableData.value);
          }
        }
        // console.log("res",res.data)
        updateItemCount(res.data.total);
      }
    })
    .catch((err) => {
      loading.value = false;
    });
};

onMounted(() => {
  handleClear();
});

// 原传值1
const ZselectedIdsArr = ref([]);
const ZidLabelMap = {};
var ZData = [];
var Zlist = [];
const ZupdateIdLabelMap = (list) => {
  ZData = list;
  Array.isArray(list) &&
    list.forEach((elem) => {
      ZidLabelMap[elem.id] = elem.title;
    });
};

const ZhandleSelect = (idsArr) => {
  Zlist = [];
  ZselectedIdsArr.value = idsArr;
  ZSelective(idsArr,ZData)
};

function ZSelective(idsArr,ZData) {
  let ArrID = []
  for (var j = 0; j < ZData.length; j++){
    for (var i = 0; i < idsArr.length; i++){
    if(ZData[j].id == idsArr[i]){
      let data = {
        id: ZData[j].id,
        title: ZData[j].title,
        type:''
      }
      if (ZData[j].type == "1") {
        data.type = "资源";
      } else if (ZData[j].type == "2") {
        data.type = "习题";
      } else if (ZData[j].type == "3") {
        data.type = "试卷";
      }
      if(ArrID.indexOf(idsArr[i])== -1){
        ArrID.push(ZData[j].id);
        Zlist.push(data);
      }
    }
  }
  }
  emit("id-label-update", Zlist)
}

// 原传值2
const JselectedIdsArr = ref([]);
const JidLabelMap = {};
var JData = [];
var Jlist = [];
const JupdateIdLabelMap = (list) => {
  JData = list
  Array.isArray(list) &&
    list.forEach((elem) => {
      JidLabelMap[elem.id] = elem.title;
    });
};
const JhandleSelect = (idsArr) => {
  Jlist = []
  JselectedIdsArr.value = idsArr;
  JSelective(idsArr,JData)
};

function JSelective(idsArr,JData) {
  let ArrID = []
  for (var j = 0; j < JData.length; j++){
    for (var i = 0; i < idsArr.length; i++){
    if(JData[j].id == idsArr[i]){
      let data = {
        id: JData[j].id,
        title: JData[j].base_name,
        name:JData[j].admin_username
      }
      // console.log('idsArr[i]',idsArr[i]);
      // console.log('ArrID',ArrID.indexOf(idsArr[i]));
      
      if(ArrID.indexOf(idsArr[i])== -1){
        ArrID.push(JData[j].id);
        Jlist.push(data);
      }
    }
  }
  }
  emit("id-jlabel-update", Jlist);
}

// 原传值3
const KselectedIdsArr = ref([]);
const KidLabelMap = {};
var KData = [];
var Klist = [];
const KupdateIdLabelMap = (list) => {
  KData = list
  Array.isArray(list) &&
    list.forEach((elem) => {
      KidLabelMap[elem.id] = elem.base_name;
    });
};

const KhandleSelect = (idsArr) => {
  Klist = []
  KselectedIdsArr.value = idsArr;
  KSelective(idsArr,KData)
};

function KSelective(idsArr,KData) {
  let ArrID = []
  for (var j = 0; j < KData.length; j++){
    for (var i = 0; i < idsArr.length; i++){
    if(KData[j].id == idsArr[i]){
      let data = {
        id: KData[j].id,
        title: KData[j].base_name,
        name:KData[j].admin_username
      }
      if(ArrID.indexOf(idsArr[i])== -1){
        ArrID.push(KData[j].id);
        Klist.push(data);
      }
    }
  }
  }
  emit("id-klabel-update", Klist);
}

// 原传值4
const SselectedIdsArr = ref([]);
const SidLabelMap = {};
var SData = [];
var Slist = [];
const SupdateIdLabelMap = (list) => {
  SData = list
  Array.isArray(list) &&
    list.forEach((elem) => {
      SidLabelMap[elem.id] = elem.title;
    });
};

const ShandleSelect = (idsArr) => {
  Slist = []
  SselectedIdsArr.value = idsArr;
  SSelective(idsArr,SData)
};

function SSelective(idsArr,SData) {
    let ArrID = []
  for (var j = 0; j < SData.length; j++){
    for (var i = 0; i < idsArr.length; i++){
    if(SData[j].id == idsArr[i]){
      let data = {
        id: SData[j].id,
        title: SData[j].title,
        name:SData[j].admin_username
      }
      if(ArrID.indexOf(idsArr[i])== -1){
        ArrID.push(SData[j].id);
        Slist.push(data);
      }
    }
  }
  }
  emit("id-slabel-update", Slist);
}

// 名称转换
function getResourceName(status) {
  // (全部显示参数不传 1教案，2习题，3试卷)
  if (status == "1") {
    return "教案库";
  } else if (status == "2") {
    return "习题库";
  } else if (status == "3") {
    return "试卷库";
  }
}

// 名称转换
function getResourceName1(status) {
  // (全部显示参数不传 1资源，2习题，3试卷)
  if (status == "1") {
    return "使用中";
  } else {
    return "已停用";
  }
}
</script>

<style lang="less" scoped>
@import "~@/styles/variables.less";
.theme-color {
  color: @theme-color;
}
.pane-num {
  background: red;
  border-radius: 50%;
  padding: 5px;
  color: #fff;
  text-align: center;
  position: absolute;
  width: 9px;
  line-height: 9px;
  text-align: center;
  font-size: 12px;
}
.pane-num-1 {
  top: 62px;
  left: 58px;
}
.pane-num-2 {
  top: 62px;
  left: 150px;
}
.pane-num-3 {
  top: 62px;
  left: 242px;
}
.pane-num-4 {
  top: 62px;
  left: 264px;
}
</style>